/* CSS For Global */
.pc-link.active {
    background-color: #dca524;
    color: #FFF;
    font-weight: 600;
}

.table thead th {
    background: #dca524 !important;
    color: #FFF;
}

.tox-notification {
    display: none !important
}

/* Hide scrollbar for Chrome, Safari and Opera */
#accordionItemUpdateTheme::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#accordionItemUpdateTheme {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.ScrollbarsCustom-ThumbY {
    background: #DCA524 !important;
    right: -5px !important;
}